var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { m } from 'framer-motion';
// @mui
import { Box } from '@mui/material';
//
import { varContainer } from './variants';
export default function MotionContainer(_a) {
    var animate = _a.animate, _b = _a.action, action = _b === void 0 ? false : _b, children = _a.children, other = __rest(_a, ["animate", "action", "children"]);
    if (action) {
        return (_jsx(Box, __assign({ component: m.div, initial: false, animate: animate ? 'animate' : 'exit', variants: varContainer() }, other, { children: children })));
    }
    return (_jsx(Box, __assign({ component: m.div, initial: "initial", animate: "animate", exit: "exit", variants: varContainer() }, other, { children: children })));
}
